<template>
    <div class="login">
        <div class="login__content">
            <LogosContainer center />
            <div class="login__text">
                <span class="fa fa-spinner fa-spin" />
                {{ $t("Accessing, please wait") }}...
            </div>
        </div>
        <vue-recaptcha
            class="w-100"
            ref="recaptchaComponent"
            size="invisible"
            @verify="loginWithToken"
            :sitekey="CONST.CAPTCHACODE"
            :loadRecaptchaScript="true"
        />
    </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';

import LogosContainer from '@/components/ui/LogosContainer.vue';
export default {
    name: 'loginSites',
    components: { LogosContainer, VueRecaptcha },
    mounted() {
        setTimeout(() => {
            this.$refs.recaptchaComponent.reset();
            this.$refs.recaptchaComponent.execute();
        }, 1000);
    },
    methods: {
        async loginWithToken(token) {
            try {
                const { data } = await this.axiosAccount.post('/api/login/sites', { token });
                let { redirect_url } = data;
                if(redirect_url) {
                    window.location.replace(redirect_url);
                }
            } catch (error) {
                this.$toasted.global.errorMessage(error);
                let query = {};
                if (error.response && error.response.status === 401) {
                    if(this.$route.query.site_id && this.$route.query.redirect_url) {
                        query = { site_id: this.$route.query.site_id, redirect_url: this.$route.query.redirect_url };
                    }
                    window.location.replace(`/login?${new URLSearchParams(query).toString()}`);
                } else {
                    this.$router.go(-1);
                }
            }
        }
    },
}
</script>

<style lang="scss">
.login {
    background-color: #ffffff;
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .fa-spinner {
        font-size: 1rem;
        color: $accounts-primary;
    }

    &__text {
        text-align: center;
    }
}
</style>